<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 16:20:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-15 17:19:11
 * @Description: 新增报价选项
-->
<style lang="less" scoped>
.not-found-price {
  text-align: center;
  color: @text_color_2;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  &:hover {
    text-decoration: underline;
    color: @primary-color;
  }
}

.tip {
  line-height: 16px;
  color: #ff2d2d;
  font-size: 12px;
}
</style>

<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_quote_newOption')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-form-model ref="form" v-bind="formLayout" :colon="true" :model="form" :rules="rules">
      <a-form-model-item prop="serviceType" :label="$t('orderInfo.quote_option_type')">
        <a-select
          allowClear
          v-model="form.serviceType"
          @change="() => (form.price = undefined)"
          :placeholder="$t('placeholder.select', { attr: $t('orderInfo.quote_option_type') })"
        >
          <a-select-option
            v-for="item in priceList"
            :key="item.serviceId"
            :value="item.serviceId"
            :disabled="selectedServiceType.includes(item.dictValue)"
          >
            {{ item.serviceLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="price" :label="$t('orderInfo.quote_option_price')">
        <a-select
          allowClear
          v-model="form.price"
          :placeholder="$t('placeholder.select', { attr: $t('orderInfo.quote_option_price') })"
        >
          <template v-slot:notFoundContent v-if="orderInfo.isOrderSpm === 1">
            <span class="not-found-price" @click="handleAddPrice">{{ $t('orderInfo.quote_no_price') }}</span>
          </template>
          <a-select-option v-for="item in cusPriceList" :key="item.id" :value="item.id">
            <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
            <span>{{ item.price }}</span> /
            <span>{{ $store.getters['app/getDictLabel']('SALE_UNIT', item.unit) | defined }}</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="amount" :label="$t('orderInfo.quote_option_number')">
        <a-input
          allowClear
          v-model="form.amount"
          autocomplete="off"
          :placeholder="$t('placeholder.input', { attr: $t('orderInfo.quote_option_number') })"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('orderInfo.remark')">
        <a-input allowClear v-model="form.remark" :placeholder="$t('orderInfo.inputRemark')" />
        <div class="tip" v-if="form.remark.length > 100">{{ $t('orderInfo.remarkTip') }}</div>
      </a-form-model-item>

      <a-form-model-item class="mb-1" :label="$t('orderInfo.quote_option_total')">
        <span class="primary" v-if="currencyUnit()">
          <span>{{ currencyUnit()[1] }}</span>
          <span class="bold">{{ totalPirce | defined }}</span>
        </span>
      </a-form-model-item>
      <!-- 添加价格 -->
      <AddPriceModal :defaultInfo="priceFormData" :visible.sync="priceVisible" @refresh="getOtherPrice" />
    </a-form-model>
  </a-modal>
</template>

<script>
import numeral from 'numeral'
import AddPriceModal from './AddPriceModal'
import { uuid } from '@/utils/utils'
const { validate } = window.$g

export default {
  name: 'AddQuoteOptionModal',
  components: {
    AddPriceModal,
  },
  props: {
    // 订单详情
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
    // 报价单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    // 已选服务类型
    selectedServiceType: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceList: [],
      formLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
      },
      form: {
        serviceType: undefined,
        price: undefined,
        amount: undefined,
        remark: '',
      },
      rules: {
        serviceType: [validate.required],
        price: [validate.required],
        amount: [validate.required, validate.float_2],
      },

      priceFormData: {},
      priceVisible: false,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getOtherPrice()
      } else {
        this.form = {
          serviceType: undefined,
          price: undefined,
          amount: undefined,
          remark: '',
        }
        this.$refs.form.resetFields()
      }
    },
    cusPriceList(arr) {
      if (arr.length === 1) {
        // 当长度只有1时，自动选择第一个值
        this.form.price = arr[0].id
      }
    },
  },
  computed: {
    // 服务类型
    serviceTypeList() {
      return this.$store.getters['app/getDictByType']('SERVICE_TYPE').filter((v) => v.dictValue.split('-')[0] === '2')
    },
    // 根据服务类型find价格列表
    cusPriceList() {
      return this.priceList.find((v) => v.serviceId === this.form.serviceType)?.supPriceList || []
    },
    // 总价
    totalPirce() {
      const { price, amount } = this.form
      if (this.$is.Defined(price) && this.$is.Defined(amount) && price && amount) {
        const p = this.cusPriceList.find((v) => v.id === price)?.price
        // return numeral(amount * p).format('0.[00]')
        return numeral(amount)
          .multiply(p || 0)
          .value()
      } else {
        return undefined
      }
    },
  },
  methods: {
    // 添加单价
    handleAddPrice() {
      const { cusAgencyId } = this.info
      const { field, documentType, currency } = this.orderInfo
      this.priceVisible = true
      this.priceFormData = {
        agencyId: cusAgencyId,
        currency,
        serviceType: this.form.serviceType,
        field,
        documentType,
      }
    },

    // 币种单位
    currencyUnit() {
      if (this.$is.Defined(this.info.currency)) {
        const unit = this.$store.getters['app/getDictLabel']('CURRENCY', this.info.currency) || ''
        return unit.split(';')
      } else {
        return false
      }
    },
    // 其他服务类型价格
    async getOtherPrice() {
      try {
        const price = await this.$http({
          key: 'orderProcessOtherPrice',
          params: {
            id: this.info.id,
          },
        })
        this.priceList = price
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid && this.form.remark.length <= 100) {
          const { price, sourceCode, targetCode, unit, serviceLabel, serviceKind } = this.cusPriceList.find(
            (v) => v.id === this.form.price
          )
          // 子组件向父组件传值
          this.$emit('addItem', {
            id: uuid(),
            new: true, // 用于判断是不是新加的
            serviceType: this.form.serviceType,
            serviceLabel,
            serviceKind,
            price,
            priceType: this.form.price,
            amount: Number(this.form.amount),
            sourceCode,
            targetCode,
            unit,
            selectivePriceList: this.cusPriceList,
            remark: this.form.remark,
          })
          this.$emit('update:visible', false)
        }
      })
    },
  },
}
</script>
