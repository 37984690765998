<template>
  <div class="quotation-detail-table">
    <NeoTitle class="mt-3" :text="$t('payment.drawer_quotation_detail')" />

    <a-table
      class="head-bg mt-3"
      size="middle"
      rowKey="id"
      :columns="columns"
      :data-source="table_"
      :pagination="false"
      :bordered="true"
    >
    </a-table>
  </div>
</template>

<script>
import numeral from 'numeral'
export default {
  name: 'QuotationDetailTable',
  props: {
    table: {
      default: [],
    },
    parents: {
      default: {},
    },
  },
  data() {
    return {
      table_: [],
      sourceTable: [],
      trows: ['Context Match', 'Repetition', '100%', '95%-99%', '85%-94%', '75%-84%', '0-74%&New'],
      tcols: ['数量', '单价', '金额', '小计', '项目管理费'],
      tr: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
      tc: ['a', 'b', 'c', 'd', 'e'],
      colrow: [],
      crMap: null,
      index: 0,
    }
  },
  watch: {
    table: {
      immediate: true,
      deep: true,
      handler(t) {
        // 把后端给的两行数据变成10行
        if (!t.length) {
          return
        }
        this.sourceTable = t
        this.renderQuoteTable(t)
      },
    },
    projectManageRatio(projectManageRatio) {
      console.log('projectManageRatio :>> ', projectManageRatio)
      const p = this.devide(projectManageRatio, 100)
      this.renderQuoteTable({ projectManageRatio: p })
    },
  },
  computed: {
    projectManageRatio() {
      return this.parents.projectManageRatio
    },
    dataSource() {
      return this.parents.dataSource
    },
    isPdf() {
      return this.parents.isPdf
    },
    columns() {
      // eslint-disable-next-line no-unused-vars
      const child = (value, row) => {
        // value = value + '99999999999999999999999'
        const v = value ? String(value) : false
        // 如果数值超过13位将会换行，需要tooltip
        const toolTip = v ? v.length > 13 : false

        if (toolTip) {
          return (
            <a-tooltip placement="topLeft">
              <template slot="title">{value}</template>
              <div class="multi-ellipsis">{value}</div>
            </a-tooltip>
          )
        } else {
          return value
        }
      }
      return [
        {
          title: this.$t('newOrder.target'),
          dataIndex: 'targetCode',
          align: 'center',
          width: 100,
          // eslint-disable-next-line prettier/prettier
          customRender: (text,row,index) => {
            const idx = index + 1
            // 判断一个序号是不是5行的第一行，用它加4除以5看能不能除尽
            return {
              children: this.$store.getters['app/getLangNameByCode'](text),
              attrs: {
                rowSpan: (idx + 4) % 5 === 0 ? 5 : 0,
                colSpan: 1,
              },
            }
          },
        },
        {
          title: this.$t('orderInfo.table_matching'),
          children: [
            {
              title: '',
              dataIndex: 'label',
              align: 'center',
              width: 90,
            },
            {
              title: 'Context Match',
              customRender: (text, row) => {
                const isTotal = ['小计', '项目管理费'].includes(row.label)

                const dom = (
                  <span style="color:#4080FF">
                    {this.parents.currencyUnit()[1]} {row.y_a}
                    {'项目管理费' === row.label && `（${this.multiply(row.fee, 100)}%）`}
                  </span>
                )
                const dom2 = child(row.y_a, row)
                return {
                  children: isTotal ? dom : dom2,
                  attrs: {
                    colSpan: this.getColSpan('Context Match', row),

                    align: isTotal ? 'right' : 'center',
                  },
                }
              },
            },
            {
              title: 'Repetition',
              dataIndex: 'Repetition',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_b, row),
                  attrs: {
                    colSpan: this.getColSpan('Repetition', row),
                  },
                }
              },
            },
            {
              title: '100%',
              dataIndex: '100%',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_c, row),
                  attrs: {
                    colSpan: this.getColSpan('100%', row),
                  },
                }
              },
            },
            {
              title: '95%-99%',
              dataIndex: '95%-99%',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_d, row),
                  attrs: {
                    colSpan: this.getColSpan('95%-99%', row),
                  },
                }
              },
            },
            {
              title: '85%-94%',
              dataIndex: '85%-94%',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_e, row),
                  attrs: {
                    colSpan: this.getColSpan('85%-94%', row),
                  },
                }
              },
            },
            {
              title: '75%-84%',
              dataIndex: '75%-84%',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_f, row),
                  attrs: {
                    colSpan: this.getColSpan('75%-84%', row),
                  },
                }
              },
            },
            {
              title: '0-74%&New',
              dataIndex: '0-74%',
              align: 'center',
              customRender: (text, row) => {
                return {
                  children: child(row.y_g, row),
                  attrs: {
                    colSpan: this.getColSpan('0-74%&New', row),
                  },
                }
              },
            },
          ],
        },
      ]
    },
  },
  methods: {
    devide(a, b) {
      return numeral(a).divide(b).value()
    },
    renderQuoteTable() {
      const projectManageRatio = this.devide(this.parents.projectManageRatio, 100) || 0
      const t = this.sourceTable
      let _price = null
      const dl = this.dataSource.length
      console.warn('this.dataSource :>> ', this.dataSource)
      if (!dl) {
        setTimeout(() => {
          this.renderQuoteTable()
        }, 0)

        return
      }

      const table = []
      t.forEach((_) => {
        const { targetCode } = _
        const fee = projectManageRatio || _.fee
        let total = 0
        let proAdminFee = 0
        for (let i = 0; i < 5; i++) {
          const r = _.rockWellQuotationDTOList
          if ([1, '1'].includes(this.parents.info.status)) {
            const _target = this.dataSource.find((_) => _.targetCode === targetCode)
            const _ptype = _target.priceType
            _price = _target.selectivePriceList?.find((_) => _.id === _ptype)?.price ?? 0
            r.forEach((_) => {
              _.price = _price
            })
          }
          const aa = this.tc[i]
          const label = this.tcols[i]
          const p3 = { total, proAdminFee }
          const obj = {
            y_a: this.getValue(r, aa + '::a', p3),
            y_b: this.getValue(r, aa + '::b', p3),
            y_c: this.getValue(r, aa + '::c', p3),
            y_d: this.getValue(r, aa + '::d', p3),
            y_e: this.getValue(r, aa + '::e', p3),
            y_f: this.getValue(r, aa + '::f', p3),
            y_g: this.getValue(r, aa + '::g', p3),
          }
          if (i === 2) {
            // 在这时做total和proAdminFee的计算
            total = this.$g.tofixed2(Object.values(obj).reduce((pre, curr) => Number(pre) + Number(curr)))
            proAdminFee = this.multiply(total, fee)
          }
          const item = {
            fee,
            targetCode,
            ...obj,
            label,
            rockWellQuotationDTOList: r,
          }

          table.push(item)
        }
      })

      this.table_ = table

      console.log(`table`, table)
    },
    multiply(a, b) {
      if (this.$g.zero(a) || this.$g.zero(b)) {
        return 0
      } else {
        return numeral(a).multiply(b).value()
      }
    },
    setMap() {
      const map = new Map()
      const colrow = this.tcols.map((tcol, idx) => {
        const tc = this.tc[idx]
        return this.trows.map((trow, idx2) => {
          const tr = this.tr[idx2]

          map.set(`${tc}::${tr}`, `${tcol}::${trow}`)

          return [`${tc}::${tr}`, `${tcol}::${trow}`]
        })
      })

      this.colrow = colrow
      this.crMap = map
    },
    getValue(rockWellQuotationDTOList, keys, { total, proAdminFee }) {
      total = total ?? 0
      proAdminFee = proAdminFee ?? 0
      if (this.index === 0) {
        this.setMap()
        this.index++
      }
      const val = this.crMap.get(keys)
      const ab = val.split('::')
      const left = ab[0]
      const top = ab[1]
      let k = ''
      const ex = ['Context Match', '0-74%&New']
      const getT = (key) => rockWellQuotationDTOList.find((_) => _.discountRange === key)
      const multiply = (a, b) => {
        const __ = numeral(a).multiply(b).value()
        return __
      }
      const add = (a, b) => {
        const __ = numeral(a).add(b).value()
        return __
      }
      const divide = (a, b) => {
        const __ = numeral(a).divide(b).value()
        return __
      }
      const computed = () => {
        let _amount, _money, _price
        if (ex.excludes(top)) {
          const target = getT(top)
          _price = multiply(target.price, target.discountValue)
          _amount = target.baseCount
          _money = multiply(_amount, _price)
        } else {
          if (top === 'Context Match') {
            const target = getT('PM/ICE')
            _price = multiply(target.price, target.discountValue)
            _amount = target.baseCount
            _money = multiply(_amount, _price)
          } else if (top === '0-74%&New') {
            const { price: p, discountValue: dv1, baseCount: bc1 } = getT('0-74%')
            const { discountValue: dv2, baseCount: bc2 } = getT('MT')
            if (bc1 + bc2 !== 0) {
              const bd1 = multiply(bc1, dv1)
              const bd2 = multiply(bc2, dv2)
              const bd1_2 = add(bd1, bd2)
              const bc1_2 = add(bc1, bc2)
              // _price = (p * (bc1 * dv1 + bc2 * dv2)) / (bc1 + bc2)
              _price = divide(multiply(p, bd1_2), bc1_2)
            } else {
              //  ((dv1 + dv2) * p) / 2
              // numeral(dv1).add(dv2).multiply(p).divide(2).value()
              _price = divide(multiply(add(dv1, dv2), p), 2)
            }

            _amount = add(bc1, bc2)
            _money = multiply(_amount, _price)
          }
        }

        const result = {
          amount: _amount,
          money: _money,
          price: _price,
        }

        return result
      }
      switch (left) {
        case '数量':
          k = computed()?.amount
          break
        case '单价':
          k = computed()?.price
          break
        case '金额':
          k = computed()?.money
          break
        case '小计':
          k = total
          break
        case '项目管理费':
          k = proAdminFee
          break
      }

      return k
    },
    // eslint-disable-next-line no-unused-vars
    getColSpan(x, item) {
      const { label: y } = item

      if (['小计', '项目管理费'].includes(y)) {
        if (x === 'Context Match') {
          return 7
        } else {
          return 0
        }
      }

      return ''
    },
  },
  created() {
    console.log(`this.table`, this.table)
  },
  mounted() {
    this.$bus.$off().$on('_refresh_quotation_detail_table', () => {
      this.renderQuoteTable()
    })
  },
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-table-body tr > td,
  .ant-table-body tr > th {
    width: 100px !important;
    max-width: 100px !important;
  }

  .ant-table-thead > tr:nth-child(2) > th:nth-child(2) div {
    text-align: center !important;
  }
}
</style>
<style lang="less">
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 1; // 超出1行则以...形式隐藏
  // -webkit-box-orient: vertical;
  cursor: pointer;
  white-space: nowrap;
}
</style>
